import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";

const tabList = ["All", "(Java/Type)script", "C", "Q&A", "AI"];
const AllPortfolioContent = [
  {
    img: "1",
    title: "Real Time Spectrum Analyzer",
    subTitle:
      "Desktop App builds using React and Electron for real-time visualization of data from spectrum analyzer.",
    alterText: "Realtime App",
    portfolioLink: "./nda",
  },
  {
    img: "2",
    title: "React App for RF Antenna",
    subTitle:
      "Participation on React.js graphical interface for 3D radio frequency tracking antenna backend written in NodeJS.",
    alterText: "Antenna app",
    portfolioLink: "./nda",
  },
  {
    img: "3",
    title: "My version of TAR library",
    subTitle:
      "My first try on something in C language. It always helps to understand memory and statically typed languages.",
    alterText: "Tar library",
    portfolioLink: "https://github.com/SirHanus/my_tar",
  },
  {
    img: "4",
    title: "System testing of Medical Devices",
    subTitle:
      "Verification and Validation of Class 2 medical devices using automatic processes (Insufflators/Pumps) for FDA/EMA approval.",
    alterText: "System testing",
    portfolioLink:
      "https://www.wom.group/en/products-services/products/insufflators-pumps/",
  },
  {
    img: "5",
    title: "Software verification",
    subTitle:
      "Verification of clients software with 90% coverage including creation of test plans, test cases and test reports.",
    alterText: "Software testing",
    portfolioLink: "./nda",
  },
  {
    img: "6",
    title: "Bachelor Thesis: Computer Vision Classification Model",
    subTitle:
      "Developed a computer vision classification model trained on synthetic data using a custom stable diffusion model.",
    alterText: "Thesis",
    portfolioLink: "./nda",
  },
  {
    img: "7",
    title: "Automation Platform for Test Procedure Conversion",
    subTitle:
      "Designed and developed an automation platform to convert test procedures into executable code.",
    alterText: "Automation Platform",
    portfolioLink: "./nda",
  },
];
const JavascriptContent = [
  {
    img: "1",
    title: "Real Time Spectrum Analyzer",
    subTitle:
      "Desktop App builds using React and Electron for real-time visualization of data from spectrum analyzer.",
    alterText: "Realtime App",
    portfolioLink: "./nda",
  },
  {
    img: "2",
    title: "Isolog React App for Antena",
    subTitle:
      "Participation on react.js graphical interface for tracking antenna.",
    alterText: "Antena app",
    portfolioLink: "./nda",
  },
];

const ClangContent = [
  {
    img: "2",
    title: "My version of TAR library",
    subTitle: "Learning with memory is usefull",
    alterText: "Tar library",
    portfolioLink: "https://github.com/SirHanus/my_tar",
  },
];
const FashionPortfolioContent = [
  {
    img: "4",
    title: "System testing of Medical Devices",
    subTitle:
      "Verification and Validation of class 2 medical devices using manual and also automatic processes (Insufflators/Pumps) from system perspective for FDA/EMA approval.",
    alterText: "System testing",
    portfolioLink:
      "https://www.wom.group/en/products-services/products/insufflators-pumps/",
  },
  {
    img: "5",
    title: "Software verification",
    subTitle:
      "Verification of clients software with 90% coverage including creation of test plans.",
    alterText: "Software testing",
    portfolioLink: "./nda",
  },
  {
    img: "7",
    title: "Automation Platform for Test Procedure Conversion",
    subTitle:
      "Designed and developed an automation platform to convert test procedures into executable code.",
    alterText: "Automation Platform",
    portfolioLink: "./nda",
  },
];

const ML = [
  {
    img: "6",
    title: "Bachelor Thesis: Computer Vision Classification Model",
    subTitle:
      "Developed a computer vision classification model trained on synthetic data using a custom stable diffusion model.",
    alterText: "Thesis",
    portfolioLink: "./nda",
  },
];

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="positon-relative">
        <div className="portfolio-filter-01">
          <Tabs>
            <TabList className="filter d-flex justify-content-center">
              {tabList.map((val, i) => (
                <Tab key={i}>{val}</Tab>
              ))}
            </TabList>
            {/* End tablist */}

            <SRLWrapper>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {AllPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                          <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a>
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {JavascriptContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                          <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a>
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {ClangContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                          <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a>
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {/* End list wrapper */}
              </TabPanel>

              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {FashionPortfolioContent.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                          <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a>
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
              <TabPanel>
                <div className="portfolio-content row lightbox-gallery">
                  {ML.map((val, i) => (
                    <div
                      className="col-sm-6 col-lg-4 grid-item product"
                      key={i}
                    >
                      <div className="portfolio-box-01">
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <h5>
                              <a
                                href={val.portfolioLink}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.title}
                              </a>
                            </h5>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}.jpg`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}.jpg`}
                              alt={val.alterText}
                            />
                          </a>
                          {/* End gallery link */}
                          <a
                            href={val.portfolioLink}
                            className="portfolio-icon"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span className="ti-link"></span>
                          </a>
                          {/* End .portfolio-icon */}
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* grid item  */}
                </div>
                {/* End list wrapper */}
              </TabPanel>
            </SRLWrapper>
            {/* End tabpanel */}
          </Tabs>
        </div>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
