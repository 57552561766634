import React from "react";

const Skills = () => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt">Education</h4>
          </div>
          <ul className="aducation-box theme-bg">
            <li>SCHOOL EDUCATION</li>
            <li>
              <h6>Computer Science</h6>
              <p>
                Secondary School of Technology and Economics Brno, Olomoucká,
                contributory organization
              </p>
            </li>
            <li>
              <h6>Computer Science</h6>
              <p>Bc. - Mendel University</p>
            </li>
            <li>
              <h6>Computer Science</h6>
              <p>M.Sc. - ZHAW School of Engineering</p>
            </li>
            <li>CERTIFICATIONS</li>
            <li>
              <h6>ECDL</h6>
              <p>European Computer Driving Licence</p>
            </li>
            <li>
              <h6>CISCO</h6>
              <p>CCNA Routing and Switching–Introduction to networks</p>
            </li>
            <li>
              <h6>Cambridge English</h6>
              <p>Certification with proficiency of C2</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}

        <div className="col-lg-4 my-3">
          <div className="sm-title">
            <h4 className="font-alt">Experience</h4>
          </div>
          <ul className="aducation-box dark-bg">
            <li>
              <span className="theme-bg">2016-2018</span>
              <h6>Camp Counselor</h6>
              <p>Táborníci Z Kančí Strže, z. s.</p>
            </li>
            <li>
              <span className="theme-bg">2019</span>
              <h6>Stock Assistant</h6>
              <p>Phoenix, s.r.o.</p>
            </li>
            <li>
              <span className="theme-bg">2020-now</span>
              <h6>Software Engineer</h6>
              <p>Consilia s.r.o.</p>
            </li>
            <li>
              <span className="theme-bg">2021-now</span>
              <h6>Q&A Test Engineer </h6>
              <p>Novanta Inc.</p>
            </li>
            <li>
              <span className="theme-bg">2022-now</span>
              <h6>Automation Developer</h6>
              <p>Novanta Inc.</p>
            </li>
          </ul>
          {/* End  .aducation-box */}
        </div>
        {/* End .col */}



        <div className="col-lg-4 ml-auto my-3">
          <div className="sm-title">
            <h4 className="font-alt">My Skills</h4>
          </div>

          <div className="gray-bg skill-box">
            <div className="row">
              <ul className="col-6 list-unstyled list-divider">
                <li>Node.js/Angular</li>
                <li>Typescript</li>
                <li>Python</li>
                <li>PyTorch</li>
                <li>Java</li>
                <li>Spring Boot</li>
                <li>Docker</li>
                <li>IT Support & Troubleshooting</li>
                <li>Quality Assurance</li>
                <li>Machine Learning</li>
                <li>Agile Methodologies</li>
                <li>Automation Strategies</li>
                <li>React.js</li>
                <li>Electron</li>
              </ul>

              <ul className="col-6 list-unstyled list-divider">
                <li>Analytical Thinking</li>
                <li>Adaptability</li>
                <li>Continuous Learning</li>
                <li>Effective Communication</li>
                <li>Collaboration</li>
                <li>Problem-Solving</li>
                <li>Creativity</li>
                <li>Teamwork</li>
                <li>Attention to Detail</li>
                <li>Time Management</li>
              </ul>
            </div>
          </div>
        </div>
        {/* End .col */}
      </div>
    </>
  );
};

export default Skills;
