import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../../components/contact/Contact";

Modal.setAppElement("#root");

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  function toggleModalTwo() {
    setIsOpen2(!isOpen2);
  }
  function toggleModalThree() {
    setIsOpen3(!isOpen3);
  }
  function toggleModalFour() {
    setIsOpen4(!isOpen4);
  }
  function toggleModalFive() {
    setIsOpen5(!isOpen5);
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalOne}>
            <div className="blog-img">
              <div className="data">
                <span>29</span>
                <small>Oct</small>
              </div>
              <img src="img/blog/blog-1.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>From hobby to business</h6>
              <p>
                Together with my girlfriend we transformed our passion of making
                candles to a working e-shop.
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalTwo}>
            <div className="blog-img">
              <div className="data">
                <span>11</span>
                <small>May</small>
              </div>
              <img src="img/blog/blog-2.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>The art of homecooking</h6>
              <p>
                Even though home cooking saves you money. Even though with just
                a small group of basic ingredients, you can make liquid gold.
                Nothing of this is the reason why I started to make my meals
                myself.
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}

        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalThree}>
            <div className="blog-img">
              <div className="data">
                <span>24</span>
                <small>Oct</small>
              </div>
              <img src="img/blog/blog-3.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Keeping my physique in check</h6>
              <p>
                On October 24th i ran my first spartan race and as I technical
                person without any prior expirience it was a big difficult
                chalange. There were times where I thought I am not gonna make
                it! SPOILER: I did make it.
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}
        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalFour}>
            <div className="blog-img">
              <div className="data">
                <span>11</span>
                <small>MAR</small>
              </div>
              <img src="img/blog/blog-4.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>Balancing Leadership and Technical Skills in CS</h6>
              <p>
                Join me on my personal journey as a computer science student
                balancing my dual role as a team leader and developer. Discover
                how I'm learning to develop my technical and leadership skills
                simultaneously, and how this experience is shaping my future
                career aspirations. Click here to read the full article.
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}
        {/* End .col for blog-1 */}
        <div className="col-lg-4 col-md-6 my-3">
          <div className="blog-post" onClick={toggleModalFive}>
            <div className="blog-img">
              <div className="data">
                <span>10</span>
                <small>MAY</small>
              </div>
              <img src="img/blog/blog-5.jpg" alt="blog post"></img>
            </div>
            {/* End blog-img */}

            <div className="blog-info">
              <h6>
                Training a Model to Generate Synthetic Data for Computer Vision
                Classification
              </h6>
              <p>
                Dive into my exploration of synthetic data generation for
                computer vision tasks. Learn how I trained a model to create
                synthetic images and utilized them to train a classification
                model. Follow along as I discuss the challenges and insights
                gained from this journey, and how it has shaped my understanding
                of machine learning and computer vision. Click here to read the
                full article.
              </p>
              <div className="btn-bar">Read More</div>
            </div>
            {/* End blog-info */}
          </div>
          {/* End blog-post */}
        </div>
        {/* End .col for blog-1 */}
      </div>
      {/* End .row */}

      {/* Start Modal for Blog-1 */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalOne}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-1.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      How me and my girlfriend created a business out of our
                      beloved hobby
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/blog/blog1-author.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY JAKUB</label>
                        <span>29 OCT 2022</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      During the years of our relationship, I and my girlfriend
                      used candles to calm down during exams or to create the
                      right atmosphere. And during this time we tried several
                      brands from the biggest to the smaller retailers and even
                      though some were really good, paying more than 500 CZK for
                      a candle was not for our poor student wallets and the
                      cheap ones from the wholesalers always let us down and
                      sometimes even made us nauseous.
                    </p>
                    <p>
                      That's why we decided to change it and make candles that
                      are not made of toxic substances such as paraffin or,
                      surprisingly, some essential oils, and after several
                      dozones candles that we made for ourselves and our family,
                      we thought of expanding this range even further while
                      maintaining quality and an affordable price.
                    </p>
                    <blockquote>
                      <p>
                        Thousands of candles can be lighted from a single
                        candle, and the life of the candle will not be
                        shortened. Happiness never decreases by being shared.
                      </p>
                      <p className="blockquote-footer">
                        <cite title="Source Title">Buddha</cite>
                      </p>
                    </blockquote>
                    <p>
                      It has not been easy, handling work, school and now the
                      candle business. But the ability to do something like
                      this, without anyone's guidance has been so rewarding and
                      mostly fun!
                    </p>
                    <p>
                      Even something as seemingly simple as a candle can be
                      changed and adjusted to fit one's image. And when you work
                      and study in computer science, something like this helps
                      you to relax. Which is sometimes overlooked.
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Have any questions or comments?</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-1 */}

      {/* Start Modal for Blog-2 */}
      <Modal
        isOpen={isOpen2}
        onRequestClose={toggleModalTwo}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalTwo}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-2.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>The art of homecooking</h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/blog/blog1-author.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY JAKUB</label>
                        <span>11 MAY 2022</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      Even though not many people home cook their meals these
                      days, I still love to do it. It is way more occasional
                      hobby than it was a few years ago.
                    </p>
                    <p>
                      Previously, it worked quite well to have households
                      prepare their own food because households were small, like
                      5–15 people sometimes. Cooking takes time, skill and
                      effort, and It's super inefficient to have each person
                      cook their own food compared to preparing larger amounts
                      at once.
                    </p>
                    <p>
                      Since it takes too much time and effort for people to cook
                      everything themselves as a single individual, they instead
                      rely on mass-produced food from corporations.
                    </p>
                    <p>
                      <b>BUT</b>
                    </p>

                    <p>
                      Cooking your food can be relaxing and is considered a form
                      of art.
                    </p>
                    <p>
                      For example, the bread you see in the background. It is a
                      sourdough bread I made. Like, where can you buy something
                      so amazingly beautiful?
                    </p>
                    <p>
                      You may think it is just bread, but this could be
                      considered an art for many people. And I am one of them.
                      For many years I tried to create bread similar to this
                      one, but it is hard to make everything as perfect as this
                      batch.
                    </p>
                    <p>
                      But there are many other benefits I gained from cooking.
                      It helped me boost my confidence. Even though it is still
                      considered mainly a women's job, it is certainly
                      impressive to many colleagues or classmates when I can
                      cook some classical Czech dishes and know the recipes by
                      memory.
                    </p>
                    <p>
                      We already know that the more people cook at home, the
                      healthier their diet, the fewer calories they consume, and
                      the less likely they are to be obese or develop type 2
                      diabetes. A growing body of scientific evidence supports
                      teaching patients how to cook meals at home as an
                      effective medical intervention for improving diet quality,
                      weight loss, and diabetes prevention.
                    </p>

                    <p>
                      That is true since I do not have the best diet. I overeat
                      a lot. But the stuff you can cook at home is usually
                      better for your health and wallet.
                    </p>
                    <p>
                      It is a life-changing skill you will be glad to master,
                      and I hope to master that one day as well.
                    </p>
                    <p>
                      If you are interested in more pictures or want to discuss
                      some recipes, feel free to email me.
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-2 */}

      {/* Start Modal for Blog-3 */}
      <Modal
        isOpen={isOpen3}
        onRequestClose={toggleModalThree}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalThree}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-3.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>Keeping my physique in check</h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/blog/blog1-author.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY JAKUB</label>
                        <span>24 OCT 2022</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      You may have been surprised to see the word “exercise” in
                      the same headline as the words “software developer.” But
                      exercise is not just about looking better physically. In
                      reality, regular exercise for software developers can help
                      improve our brain, memory, problem-solving skills, and
                      overall mental agility: things that are rarely talked
                      about but necessary in our daily lives, especially when
                      dealing with complex problems such as developing new
                      components, solving bugs, or even having architectural or
                      difficult meetings.
                    </p>
                    <p>
                      In this article, I am going to explain the many ways in
                      which physical exercise for software developers is
                      important, provide a list of the cognitive benefits of
                      regular exercise, and show how they can help us become
                      better at our jobs.
                    </p>
                    <p>
                      Many studies have suggested that the parts of the brain
                      that control thinking and memory (the prefrontal cortex
                      and medial temporal cortex) are greater in volume in
                      people who exercise versus people who don’t.
                    </p>
                    {/* <blockquote>
                      <p>
                        Engaging in a program of regular exercise of moderate intensity over six months or a year is associated with an increase in the volume of selected brain regions
                      </p>
                      <p className="blockquote-footer">

                        <cite title="Source Title">Dick Grayson</cite>
                      </p>
                    </blockquote> */}
                    <p>
                      This is one of the reasons why I was interested in
                      calisthenics and running. Perfect way to test those two
                      are OCRs races (Obstacle Course Running).
                    </p>
                    <p>
                      I ran my first OCR called Spartan Race many years later.
                      You may have heard of it.
                    </p>

                    <p>
                      I had been training for two months before, which was
                      ultimately needed. To make things even worse, I have
                      recently been struggling with a form of back pain (sort of
                      mainstream for a developer I know) that comes on when I am
                      crawling, climbing, or making sudden movements, so this
                      was going to be a challenging and interesting race for me.
                      This was my first ever OCR a year ago, so I need to run
                      another this year.
                    </p>
                    <p>
                      The race was in late October, so the weather could be from
                      hot summer to a freezing winter. As I found out later, the
                      winter won. For the whole race, it was 0 degrees Celsius
                      (32 Fahrenheit if you are stuck in the middle ages).
                      Luckily that freezing temperature meant I wanted to run
                      way more than I usually would. I needed that because
                      running was the most difficult part of the race. I ran the
                      10k version of the race, which I expected would be fairly
                      easy since I could run 10+k without stress. But the
                      600-plus meters of elevation made it feel like 20k.
                    </p>

                    <p>
                      This was and still is my biggest takeaway. For the next
                      Spartan Race, which I want to run this Spring (2023), I
                      need to be able to run at least 30km.
                    </p>
                    <p>Is that possible, and will it help?</p>
                    <p>
                      I don't know. Once I know the answer, I'll let you know in
                      another blog post. ;)
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* End  Modal for Blog-3 */}

      {/* Start Modal for Blog-3 */}
      <Modal
        isOpen={isOpen4}
        onRequestClose={toggleModalFour}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalFour}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-4.jpg" alt="blog post"></img>
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>Balancing Leadership and Technical Skills in CS</h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/blog/blog1-author.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY JAKUB</label>
                        <span>11 MARCH 2023</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      As someone studying computer science and working as both a
                      team leader and developer, I'm finding this dual role to
                      be incredibly rewarding. Not only am I able to apply my
                      technical skills to the projects I'm working on, but I'm
                      also able to develop my leadership and project management
                      skills.
                    </p>
                    <p>
                      One of the most exciting aspects of this role has been the
                      opportunity to develop skills that I didn't know I lacked.
                      While I've always been interested in technology and had a
                      strong foundation in programming and software development,
                      leading a team has taught me the importance of
                      communication, delegation, and coaching. I'm constantly
                      learning how to motivate my team members, set goals, and
                      provide constructive feedback. I take pride in seeing my
                      team members grow and develop their skills, and I'm always
                      looking for ways to improve our processes and
                      collaboration.
                    </p>
                    <p>
                      At the same time, developing software is still a passion
                      of mine, and I'm excited to be able to work on technical
                      projects alongside my team. Recently, I've been working on
                      developing an innovative platform using my technical
                      skills in C#, which has given me the opportunity to learn
                      new libraries and frameworks and think creatively about
                      how to solve complex technical problems. I find that I
                      thrive in this kind of environment, where I'm constantly
                      being challenged to learn and improve.
                    </p>
                    <p>
                      Overall, I'm finding this dual role to be incredibly
                      fulfilling, and I believe it's giving me a unique
                      perspective on both the technical and leadership aspects
                      of software development. If you're looking for someone
                      who's committed to continuous learning and improvement and
                      who can bring both technical and leadership skills to your
                      team, I believe I would be an excellent fit.
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box news */}
      </Modal>
      {/* Start Modal for Bachelor Thesis */}
      <Modal
        isOpen={isOpen5}
        onRequestClose={toggleModalFive}
        contentLabel="My dialog"
        className="custom-modal"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={toggleModalFive}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner">
            <div className="scrollable">
              <div className="blog-grid">
                <div className="blog-img">
                  <img src="img/blog/blog-5.jpg" alt="blog post" />
                </div>
                {/* End blog-img */}
                <article className="article">
                  <div className="article-title">
                    <h2>
                      Training a Model to Generate Synthetic Data for Computer
                      Vision Classification
                    </h2>
                    <div className="media">
                      <div className="avatar">
                        <img src="img/blog/blog1-author.jpg" alt="thumbnail" />
                      </div>
                      <div className="media-body">
                        <label>BY JAKUB</label>
                        <span>10 MAY 2024</span>
                      </div>
                    </div>
                  </div>
                  {/* End .article-title */}

                  <div className="article-content">
                    <p>
                      In my bachelor thesis, I delved into the fascinating world
                      of synthetic data generation for computer vision tasks. I
                      embarked on this journey with the goal of training a model
                      capable of creating synthetic images that mimic real-world
                      scenarios.
                    </p>
                    <p>
                      Leveraging techniques from the field of latent diffusion models (LDMs), I developed a custom model
                      architecture tailored to the specific requirements of my
                      task based on Stable Diffusion. Through rigorous experimentation and fine-tuning, I
                      optimized the model to generate high-quality synthetic
                      images that closely resemble the characteristics of the
                      target dataset.
                    </p>
                    <p>
                      With the synthetic data in hand, I then proceeded to train
                      a classification model using PyTorch and it's torchvisions pretrained library. By feeding the
                      synthetic images into the model, I aimed to demonstrate
                      the effectiveness of using synthetic data for training
                      computer vision algorithms.
                    </p>
                    {/* <blockquote>
                      <p>
                        "The ability to generate synthetic data opens up new
                        avenues for training machine learning models in
                        scenarios where real-world data is limited or difficult
                        to obtain."
                      </p>
                      <p className="blockquote-footer">
                        <cite title="Source Title">Ing. Tomas Jaros - M</cite>
                      </p>
                    </blockquote> */}
                    <p>
                      The journey of exploring synthetic data generation and its
                      application in computer vision has been both challenging
                      and rewarding. Through this endeavor, I have gained
                      valuable insights into the intricacies of machine learning
                      and expanded my skill set in model development and
                      optimization.
                    </p>
                    <p>
                      As I continue to delve deeper into the realm of artificial
                      intelligence and machine learning, I look forward to
                      exploring further advancements in synthetic data
                      generation and its impact on the future of computer vision
                      research.
                    </p>
                  </div>
                  {/* End article content */}
                </article>
                {/* End Article */}

                <div className="contact-form article-comment">
                  <h4>Leave a Reply</h4>
                  <Contact />
                </div>
                {/* End .contact Form */}
              </div>
            </div>
          </div>
        </div>
        {/* End modal box for Bachelor Thesis */}
      </Modal>
      {/* End Modal for Bachelor Thesis */}
    </>
  );
};
export default Blog;
